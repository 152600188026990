import { extendTheme } from '@chakra-ui/react';

export const theme = extendTheme({
  colors: {
    primary: {
      900: '#0a272d',
      800: '#0e373f',
      700: '#124651',
      600: '#103e48',
      500: '#144e5a',
      400: '#43717b',
      300: '#5b838c',
      200: '#72959c',
      100: '#b9cace',
      50: '#e8edef',
    },
  },
  fonts: {
    heading: 'Open Sans',
    body: 'Open Sans',
  },
});
