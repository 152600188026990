import React from 'react';

import { Container, Flex, Text, Center, Link, LinkOverlay, Box, Image, LinkBox } from '@chakra-ui/react';

import { ShowIf } from '../../components';

export const Home: React.FC = () => {
  const footerList = [
    { label: 'Home', link: '#' },
    { label: 'Job', link: '#' },
    { label: 'Blog', link: '#' },
    { label: 'Privacy', link: '#' },
    { label: 'Terms', link: '#' },
    { label: 'Attributions', link: '#' },
  ];

  return (
    <Box bg="white">
      <Container maxW="xl">
        <Flex flexDir="column" h="100vh">
          <LinkBox mt={5}>
            <LinkOverlay href="/">
              <Image src={`${process.env.PUBLIC_URL}/logo.svg`} width={150} height="auto" />
            </LinkOverlay>
          </LinkBox>
          <Center flex={1} alignSelf="center" flexDir="column" h={400}>
            <Text fontSize="3xl" fontWeight="semibold">
              👨‍⚕️ Hey!
            </Text>

            <Text my={4} textAlign="center" letterSpacing={0.5}>
              We have just started! Our vision is to reinvent edutech for medical professionals.
            </Text>

            <Text fontSize="xs" letterSpacing={0.5}>
              Currently only invited user can sign up.
            </Text>

            <Flex flexDir="row" mt={21} mb={-30}>
              <Image src={`${process.env.PUBLIC_URL}/images/google-play-badge.png`} mx={1} width={120} objectFit="contain" cursor="not-allowed" />
              <Image
                src={`${process.env.PUBLIC_URL}/images/badge-download-on-the-app-store.svg`}
                mx={1}
                width={95}
                objectFit="contain"
                cursor="not-allowed"
              />
            </Flex>
          </Center>

          <Flex flexDir="row" my={3} fontSize={11} justifyContent="center" color="primary.300">
            {footerList.map((item, index, arr) => {
              return (
                <>
                  <Link href={item.link} mx={0}>
                    {item.label}
                  </Link>

                  <ShowIf cond={index < arr.length - 1}>
                    <Text mx={1} color="gray.500">
                      ・
                    </Text>
                  </ShowIf>
                </>
              );
            })}
          </Flex>
        </Flex>
      </Container>
    </Box>
  );
};
